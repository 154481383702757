// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cheatsheets-js": () => import("./../../../src/pages/cheatsheets.js" /* webpackChunkName: "component---src-pages-cheatsheets-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-hw-js": () => import("./../../../src/pages/hw.js" /* webpackChunkName: "component---src-pages-hw-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polyglot-js": () => import("./../../../src/pages/polyglot.js" /* webpackChunkName: "component---src-pages-polyglot-js" */),
  "component---src-pages-recap-1-js": () => import("./../../../src/pages/recap1.js" /* webpackChunkName: "component---src-pages-recap-1-js" */),
  "component---src-pages-unit-1-js": () => import("./../../../src/pages/unit1.js" /* webpackChunkName: "component---src-pages-unit-1-js" */),
  "component---src-pages-unit-2-js": () => import("./../../../src/pages/unit2.js" /* webpackChunkName: "component---src-pages-unit-2-js" */),
  "component---src-pages-unit-3-js": () => import("./../../../src/pages/unit3.js" /* webpackChunkName: "component---src-pages-unit-3-js" */),
  "component---src-pages-unit-4-js": () => import("./../../../src/pages/unit4.js" /* webpackChunkName: "component---src-pages-unit-4-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

